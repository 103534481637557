<template>
  <div class="content records flex flex-column">
    <h1>Burn Test</h1>

    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between gap-2 w-100">
        <div class="flex gap-8">
          <date-range
            v-model="filters"
            @input="fetchRecords()"
          />

          <div class="flex flex-column gap-1">
            <div>Search:</div>
            <b-form-input
              v-model="filters.search"
              placeholder="Search"
              size="sm"
              autocomplete="off"
              trim
              type="search"
              debounce="500"
              @change="fetchRecords()"
              class="search-field"
            ></b-form-input>
          </div>

        </div>
        <div class="flex gap-2">
          <b-button
            size="sm"
            variant="primary"
            class="text-nowrap"
            @click="createRecord()"
          >
            New test
          </b-button>
        </div>
      </div>

      <div class="font-bold">
        Showing {{ records.length | format }} / {{ recordCount | format }} record(s)
      </div>

      <table class="table table-google table-records table-highlight">
        <thead>
          <tr>
            <th class="text-nowrap">Date</th>
            <th class="text-nowrap">Sample no.</th>
            <th class="text-nowrap">Wick</th>
            <th class="text-nowrap">Fragrance</th>
            <th class="text-nowrap">Cold throw</th>
            <th class="text-nowrap">Melt Pool</th>
            <th class="text-nowrap">Flame</th>
            <th class="text-nowrap">Smoke</th>
            <th class="text-nowrap">Hot throw</th>
            <th class="text-nowrap">Jar temp.</th>
            <th class="text-nowrap">Result</th>
            <th class="text-nowrap">Notes</th>
            <th class="text-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading || records.length === 0">
            <td colspan="12">
              <div class="font-bold">
                <span v-if="isLoading">Loading..</span>
                <span v-else-if="records.length === 0">No records found</span>
              </div>
            </td>
          </tr>

          <tr
            v-for="record in records"
            :key="record.id"
            class="record"
            :class="{ 'active': lastActiveRecord === record.id }"
          >
            <td class="row-fit">{{ record.sample_date | isodate }}</td>
            <td class="text-nowrap">{{ record.sample_number }}</td>
            <td class="text-nowrap">{{ record.wick_option_value }}</td>
            <td class="text-nowrap">
              <div>
                <span>
                  {{ record.fragrance_id === 0 ? 'None' : record.fragrance_option_value }}
                </span>
                <span v-if="record.fragrance_pct_id">
                  ({{ record.fragrance_pct_option_value }}%)
                </span>
              </div>
            </td>
            <td class="text-nowrap">{{ record.cold_throw }}</td>
            <td class="text-nowrap">{{ record.melt_pool }}</td>
            <td class="text-nowrap">{{ getOptionText(record.flame, 'flame') }}</td>
            <td class="text-nowrap">{{ getOptionText(record.smoke, 'smoke') }}</td>
            <td class="text-nowrap">{{ record.hot_throw }}</td>
            <td class="text-nowrap">{{ getOptionText(record.jar_temp, 'jar_temp') }}</td>
            <td class="text-nowrap">{{ getOptionText(record.sample_result, 'sample_result') }}</td>
            <td class="row-fit text-nowrap">{{ getFirstLine(record.sample_note) }}</td>
            <td class="row-fit">
              <div class="flex gap-2">
               <b-button
                  variant="primary"
                  size="sm"
                  @click="editRecord(record)"
                  :disabled="isLoading"
                >
                  Open
                </b-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <b-pagination
        v-model="filters.page"
        :total-rows="recordCount"
        :per-page="filters.per_page"
        @input="fetchRecords(true)"
        :disabled="isLoading"
      ></b-pagination>

    </div>

    <sample-modal
      v-if="recordModal.showModal"
      :data="recordModal"
      :options="options"
      @change="onRecordChange"
      @delete="onRecordDelete"
    />
  </div>
</template>

<script>
import { getFirstLine } from '@/helpers';

const DateRange = () => import('@/components/invoices/DateRange.vue');
const SampleModal = () => import('@/components/burntest/SampleModal.vue');

export default {
  name: 'BurnTesting',
  components: {
    DateRange,
    SampleModal,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      records: [],
      recordCount: 0,
      lastActiveRecord: null,
      filters: {
        date_from: null,
        date_to: null,
        page: 1,
        per_page: 50,
        search: '',
      },
      options: {
        cold_throw: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
        ],
        flame: [
          { text: 'Underwick', value: 'underwick' },
          { text: 'Correctwick', value: 'correctwick' },
          { text: 'Overwick', value: 'overwick' },
        ],
        jar_temp: [
          { text: '< 70°C', value: 'nominal' },
          { text: '≥ 70°C', value: 'overheat' },
        ],
        melt_pool: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
        ],
        hot_throw: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
        ],
        smoke: [
          { text: 'Yes', value: 1 },
          { text: 'No', value: 0 },
        ],
        sample_result: [
          { text: 'Pass', value: 'pass' },
          { text: 'Fail', value: 'fail' },
        ],
      },
      recordModal: {
        showModal: false,
        options: {},
        record: null,
      },
    };
  },
  methods: {
    getOptionText(value, optionName) {
      const option = this.options[optionName].find((item) => item.value === value);
      return option?.text ?? '';
    },
    fetchRecordData() {
      this.loadingCount++;
      this.$http
        .get('/burntest')
        .query(this.filters)
        .then((res) => {
          this.records = res.body.records;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch records: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecordCount() {
      this.loadingCount++;
      this.$http
        .get('/burntest_count')
        .query(this.filters)
        .then((res) => {
          this.recordCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecords(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchRecordCount();
        this.filters.page = 1;
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchRecordData();
    },
    createRecord() {
      this.loadingCount++;
      this.$http
        .post('/burntest')
        .then((res) => {
          this.records.unshift(res.body.record);
          this.fetchRecordCount();
          this.editRecord(res.body.record);
        })
        .catch((err) => {
          this.$toast.error(`Failed to create record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    editRecord(record) {
      this.recordModal.record = record;
      this.recordModal.showModal = true;
      this.lastActiveRecord = record.id;
    },
    onRecordChange() {
      this.fetchRecords(true);
    },
    onRecordDelete(recordId) {
      this.records = this.records.filter((record) => record.id !== recordId);
      this.fetchRecordCount();
    },
    getFirstLine(text) {
      return getFirstLine(text);
    },
  },
};
</script>

<style lang="scss" scoped>
  .records {
    max-width: 1800px;
  }

  .table-records .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 20px;
  }

  .search-field {
    width: 250px;
  }
</style>
